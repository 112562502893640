@import "../../../variables";
@import "../../../mixins";
@import "../../../helperClasses";

.banner-images {
    @include bottom-padding;
    .text-below-image-block {
        h4, .subtitle {
            font-weight: 300;
            @include media-breakpoint-down(xxl) {
                font-size: 22px;
                line-height: 30px;
            }
            @include media-breakpoint-down(md) {
                font-size: 18px;
                line-height: 28px;
            }
        }
    }
    .right-text-banner {
        img {
            @include media-breakpoint-down(sm) {
                object-position: right;
            }
        }
    }
    .left-text-banner {
        img {
            @include media-breakpoint-down(sm) {
                object-position: left;
            }
        }
    }
    .vimeo-video-frame {
        max-height: 334px;
        @include media-breakpoint-up(sm) {
            max-height: 256px;
        }
        @include media-breakpoint-up(md) {
            max-height: 354px;
        }
        @include media-breakpoint-up(lg) {
            max-height: 449px;
        }
        @include media-breakpoint-up(xl) {
            max-height: 545px;
        }
        @include media-breakpoint-up(xxl) {
            max-height: 750px;
        }
        
    }
}
.kii-carousel {
    .banner-text-block-wrapper {
        opacity: 0;
        transition: all 2s;
        &.active {
            opacity: 1;
            transition: all 1s;
        }
    }
}
.banner {
    .slick-dotted.slick-slider {
        margin-bottom: 0;
    }
    picture {
        img {
            position: relative;
            z-index: 1;
            @include media-breakpoint-down(md) {
                width: 100%;
                object-fit: cover;
            }
        }
    }
}
.main-slider {
    @include media-breakpoint-down(sm) {
        // min-height: 600px;
        // padding-bottom: 70px;
    }
    .kii-carousel-controls {
        // padding: 10px 0 0 0;
        padding: 0;
        @include media-breakpoint-down(sm) {
            bottom: 10px;
            bottom: 15px;
        }
        
        @include media-breakpoint-up(sm) {
            padding: 12px 0 0 0;
        }
        @include media-breakpoint-up(md) {
            padding: 13px 0 0 0;
        }
        @include media-breakpoint-up(lg) {
            padding: 15px 0 0 0;
        }
        @include media-breakpoint-up(xl) {
            padding: 17px 0 0 0;
        }
        @include media-breakpoint-up(xxl) {
            padding: 20px 0 0 0;
        }
        .container {
            @include media-breakpoint-down(sm) {
                justify-content: flex-start;
            }
        }
    }

    .kii-carousel-controls {
        @include media-breakpoint-up(sm) {
            top: 256px;
        }
        @include media-breakpoint-up(md) {
            top: 345px;
        }
        @include media-breakpoint-up(lg) {
            top: 449px;
        }
        @include media-breakpoint-up(xl) {
            top: 545px;
        }
        @include media-breakpoint-up(xxl) {
            top: 750px;
        }
    }
    .banner-images {
        @include media-breakpoint-down(sm) {
            padding-bottom: 70px;
        }
        .mobile-image-carousel {
            .slick-slide {
                @include media-breakpoint-down(sm) {
                    height: 334px;
                    overflow: hidden;
                }
            }
        }
    }
    .hero-banner {
        @include media-breakpoint-down(sm) {
            padding-bottom: 70px;
        }
    }
    .slick-slide {
        .kii-custom-slide {
            display: block !important;
        }
        &.slick-active {
            display: inline-block !important;
        }
    }
}
.banner-with-one-image {
    .banner-text-block {
        margin-top: 10px;
        @include media-breakpoint-up(sm) {
            margin-top: 12px;
        }
        @include media-breakpoint-up(md) {
            margin-top: 13px;
        }
        @include media-breakpoint-up(lg) {
            margin-top: 15px;
        }
        @include media-breakpoint-up(xl) {
            margin-top: 17px;
        }
        @include media-breakpoint-up(xxl) {
            margin-top: 20px;
        }
    }
}

.banner-with-two-images {
    .title-block {
        h1 {
            @include header(2);
        }
        h2, h1.Title {
            font-weight: $font-weight-light;
            margin-bottom: 7px;
            @include media-breakpoint-up(xxl) {
                line-height: 35px;
            }
            @include media-breakpoint-down(xxl) {
                font-size: 30px;
                line-height: 30px;  
            }
            @include media-breakpoint-down(md) {
                font-weight: 400;
                font-size: 22px;
                line-height: 28px;
            }
        }
    }
    .small-img-block {
        display: none;
        @include media-breakpoint-up(sm) {
            display: block;
        }
    }
    .banner-text-block-wrapper-mobile {
        margin-top: 10px;
        @include media-breakpoint-up(sm) {
            margin-top: 12px;
        }
        @include media-breakpoint-up(md) {
            margin-top: 13px;
        }
        @include media-breakpoint-up(lg) {
            margin-top: 15px;
        }
    }
    .banner-text-block-right {
        @include media-breakpoint-only(xl) {
            margin-left: 40px;
        }
    }
    .banner-text-block-full-width {
        margin-top: 10px;
        @include media-breakpoint-up(sm) {
            margin-top: 12px;
        }
        @include media-breakpoint-up(md) {
            margin-top: 13px;
        }
        @include media-breakpoint-up(lg) {
            margin-top: 15px;
        }
        @include media-breakpoint-up(xl) {
            margin-top: 17px;
        }
        @include media-breakpoint-up(xxl) {
            margin-top: 20px;
        }
        h1.Title {
            @include header(3);
        }
        h3, h4, h1.Title {
            @include media-breakpoint-only(lg) {
                font-size: 22px;
                line-height: 30px;
            }
        }
    }
    &.banner-layout-4 {
        .small-image {
            .banner-text-block {
                @include media-breakpoint-up(xxl) {
                    margin-top: 130px;
                    .button-primary {
                        margin-top: 10px;
                    }
                }
            }
        }
    }
    &.banner-layout-5b {
        .button-primary {
            @include media-breakpoint-up(xxl) {
                margin-top: 10px;
            }
        }
        .small-image.img-bottom-allign {
            .img-info-overlay-parent {
                .img-info-overlay {
                    .info-overlay-container {
                        .info-overlay-text-block {
                            .info-overlay-content {
                                .info-overlay-content-block {
                                    @include media-breakpoint-only(xl) {
                                        max-height: 203px;
                                    }
                                    @include media-breakpoint-only(lg) {
                                        max-height: 150px;
                                    }
                                    @include media-breakpoint-only(md) {
                                        max-height: 90px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.layout-5a {
        .button-primary {
            @include media-breakpoint-up(xxl) {
                margin-top: 10px;
            }
        }
    }
    &.banner-layout-3 {
        .button-primary {
            @include media-breakpoint-up(xxl) {
                margin-top: 10px;
            }
        }
    }
    &.banner-layout-3,
    &.banner-layout-4 {
        h1.Title {
            @include header(1);
        }
        h2 {
            &:not(.h4) {
                @include header(1);
            }
        }
    }
}

.banner-with-one-image {
    h1.Title {
        @include header(3);
    }
    h3, h1.Title {
        @include media-breakpoint-down(xl) {
            font-size: 22px;
            line-height: 30px;
        }
        @include media-breakpoint-down(md) {
            font-size: 18px;
            line-height: 28px;
        }
    }
    .img-info-overlay-parent {
        .img-info-overlay {
            @include media-breakpoint-only(md) {
                width: 50%;
            }
        }
    }
}

.spacing-block {
    @include media-breakpoint-up(xl) {
        margin-top: 17px;
        min-height: 110px;
    }
    @include media-breakpoint-up(xxl) {
        margin-top: 20px;
    }
}

.slick-dots {
    display: block;
}

